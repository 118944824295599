<template>
  <div class="workspace__container__view">
    <div class="workspace__container__view__header">
      <div class="workspace__container__view__header__filter">
        <div class="container__inputs">
          <div class="settings-input">
            <label>{{ $t("workspace.generate_block.date_init") }}</label>
            <input type="date" v-model="filterModel.dateInit">
          </div>
          <div class="settings-input">
            <label>{{ $t("workspace.generate_block.date_end") }}</label>
            <input type="date" v-model="filterModel.dateEnd">
          </div>
        </div>
      </div>
    </div>

    <table class="workspace__container__view__table">
      <thead>
        <tr>
          <th>{{ $t("workspace.generate_block.description") }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in mockData" :key="item.description">
          <td class="checkbox">
            <input type="checkbox" :id="item.description"> 
            <label :for="item.description">{{item.description}}</label> 
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import { ref } from '@vue/reactivity'

export default {
  setup() {
    const mockData = ref([{
      description: 'PWR Pinamar'
    },{
      description: 'Pwr Gesell'
    },{
      description: 'Pwr Madiariaga'
    }]);

    const filterModel = ref({
      dateInit: '',
      dateEnd: ''
    })


    return {
      mockData,
      filterModel
    }
  }
}
</script>
<style lang="">
  
</style>